<template>
  <div style="background:#fff;padding:15px;">
    <div>
      <span>支付平台：</span>
      <a-select v-model:value="value" label-in-value style="width: 120px" placeholder="请选择" :options="options"
        @change="handleChange" allowClear>
      </a-select>
      <a-button style="margin-left:15px" type="primary" @click="confirmChange" :loading="btnLoading">确认</a-button>
    </div>
    <div style="display:flex;align-items:center;margin:10px 0">
      <div style="margin-right:15px;">当前支付平台：{{ platFormText }}</div>
      <a-space v-show="newPlatForm === 2">
        <a-button @click="addDataShow = true, titleData = '新增'">新增</a-button>
        <a-button @click="resetData" :loading="loading">重置表格</a-button>
      </a-space>
    </div>
    <div v-show="newPlatForm === 2">
      <a-table :columns="columns" :row-key="record => record.id" bordered :data-source="listData" :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #operate="{ record }">
          <div>
            <a @click="editData(record)">编辑</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="addDataShow" :title="titleData" @ok="confirmData" :confirm-loading="btnLoading">
    <div>
      <a-form layout="vertical" ref="formRef" :model="formData" :rules="rules">
        <a-form-item label="子商户号" name="vspCusId">
          <a-input v-model:value="formData.vspCusId" />
        </a-form-item>
        <a-form-item label="商户号状态" name="status">
          <a-select v-model:value="formData.status">
            <a-select-option :value="1">正常可用</a-select-option>
            <a-select-option :value="2">系统停用</a-select-option>
            <a-select-option :value="3">手动停用</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted, createVNode } from 'vue'
import { changePayPlatform, getPayPlatform, getVspCusConfPage, saveVspCusConf } from '@/api/yst/yst'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default {
  setup () {
    const formRef = ref(null)
    const state = reactive({
      platForm: null,
      newPlatForm: null,
      loading: false,
      btnLoading: false,
      addDataShow: false,
      platFormText: '',
      titleData: '新增',
      formData: {
        id: null,
        vspCusId: '',
        status: null
      },
      detailData: {},
      mirrorFormData: {},
      rules: {
        vspCusId: { required: true, message: '请填写子商户号', trigger: 'blur' },
        status: { required: true, message: '请选择商户号状态', trigger: 'blur', type: 'number' }
      },
      listData: [],
      options: [{
        label: '杉德',
        value: 1
      }, {
        label: '通联',
        value: 2
      }],
      pagination: {
        current: 1,
        pageSiz: 10,
        total: null,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        { title: 'id', dataIndex: 'id' },
        { title: '子商户号', dataIndex: 'vspCusId' },
        { title: '商户号状态', dataIndex: 'status.label' },
        { title: '操作', slots: { customRender: 'operate' } }
      ]
    })
    onMounted(() => {
      getPlatform()
      state.mirrorFormData = JSON.stringify(state.formData)
    })
    const getListData = () => {
      state.loading = true
      getVspCusConfPage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    const resetData = () => {
      state.pagination.current = 1
      getListData()
    }
    const handleTableChange = page => {
      state.pagination = page
      getListData()
    }
    const getPlatform = () => {
      getPayPlatform().then(res => {
        if (res.code === 10000) {
          state.newPlatForm = res.data
          switch (state.newPlatForm) {
            case 1:
              state.platFormText = '杉德'
              break
            case 2:
              state.platFormText = '通联'
              getListData()
              break
          }
        }
      })
    }
    const handleChange = (e, v) => {
      state.platForm = v.value
    }
    const confirmChange = () => {
      if (state.platForm) {
        Modal.confirm({
          title: () => '确认切换支付方式吗?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () =>
            createVNode(
              'div',
              {
                style: 'color:red;'
              },
              ''
            ),
          onOk () {
            state.btnLoading = true
            return new Promise((resolve, reject) => {
              changePayPlatform({ payTypeSwitch: state.platForm }).then(res => {
                if (res.code === 10000) {
                  message.success('修改成功')
                  getPlatform()
                }
              }).finally(() => { state.btnLoading = false,resolve() })
            })
          }
        })
      } else message.error('请选择支付平台')
    }
    const editData = record => {
      const data = JSON.stringify(record)
      state.formData = JSON.parse(data)
      if (state.formData.status) state.formData.status = JSON.parse(data).status.value
      state.titleData = '编辑'
      state.addDataShow = true
    }
    const confirmData = () => {
      formRef.value.validate().then(() => {
        state.btnLoading = true
        saveVspCusConf(state.formData).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getListData()
            state.addDataShow = false
            state.btnLoading = false
            setTimeout(() => {
              state.formData = JSON.parse(state.mirrorFormData)
            }, 500)
          }
        })
      })
    }

    return {
      ...toRefs(state),
      formRef,
      editData,
      resetData,
      confirmData,
      handleChange,
      confirmChange,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped></style>
